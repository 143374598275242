import React from "react";
import { Link } from "gatsby";
import {
  feed__item,
  feed__itemMetaTime,
  feed__itemMetaDivider,
  feed__itemMetaCategoryLink,
  feed__itemTitle,
  feed__itemTitleLink,
  feed__itemDescription,
  feed__itemReadmore,
} from "./feed.module.scss";

const Feed = ({ nodes }) => (
  <div>
    {nodes.map((node) => {
      return (
        <div className={feed__item} key={node.fields.slug}>
          <div>
            <time
              className={feed__itemMetaTime}
              dateTime={new Date(node.frontmatter.date).toLocaleDateString(
                "en-US",
                {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                }
              )}
            >
              {new Date(node.frontmatter.date).toLocaleDateString("en-US", {
                year: "numeric",
                month: "long",
              })}
            </time>
            <span className={feed__itemMetaDivider} />
            <span>
              <Link
                to={node.fields.categorySlug}
                className={feed__itemMetaCategoryLink}
              >
                {node.frontmatter.category}
              </Link>
            </span>
          </div>
          <h2 className={feed__itemTitle}>
            <Link className={feed__itemTitleLink} to={node.fields.slug}>
              {node.frontmatter.title}
            </Link>
          </h2>
          <p className={feed__itemDescription}>
            {node.frontmatter.description}
          </p>
          <Link className={feed__itemReadmore} to={node.fields.slug}>
            Read
          </Link>
        </div>
      );
    })}
  </div>
);

export default Feed;
