import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import SeoHelmet from "../components/seo-helmet";
import Sidebar from "../components/sidebar";
import Feed from "../components/feed";
import Page from "../components/page";

const BlogIndex = ({ data }) => {
  const { nodes } = data.allMarkdownRemark;
  return (
    <Layout>
      <SeoHelmet />
      <Sidebar isIndex />
      <Page>
        <Feed nodes={nodes} />
      </Page>
    </Layout>
  );
};

export default BlogIndex;

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        fields {
          slug
          categorySlug
        }
        frontmatter {
          title
          date(formatString: "MMMM DD, YYYY")
          category
          description
        }
      }
    }
  }
`;
